import Vue from 'vue'
import App from './App.vue'
import VueScrollTo from 'vue-scrollto'

import "normalize.css"

Vue.use(VueScrollTo, {
	offset: -100
})

new Vue({
	el: "#app",
	render: h => h(App)
})
