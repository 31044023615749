<template>
	<div id="request-a-demo">
		<div class="box">
			<h2>Deepen the connection</h2>
			<p>GoodTalk helps media outlets, product companies, advocacy groups and governments improve audience engagement.</p>
			<a href="#" v-scroll-to="'#learn-more'" class="button">request a demo</a>
		</div>
		<div class="image"></div>
	</div>
</template>

<script>
	export default {
		name: 'request-a-demo'
	}
</script>

<style lang="scss">
	@import "../assets/sass/global";

	#request-a-demo {
		position: relative;

		@media #{$xl} {
			width: 1200px;
			margin: 0 auto;

			&:after {
				content: "";
				display: table;
				clear: both;
			}
		}

		.box {
			position: relative;
			box-sizing: border-box;
			padding: 48px 16px;
			margin: 0 8px 16px;
			border-radius: 8px;
			background-color: white;
			box-shadow: 0 0 24px 0 rgb(241, 241, 241);
			z-index: 3;

			h2 {
				font-size: 32px;
				font-weight: 600;
				line-height: 1;
				margin: 0 0 24px;
			}

			p {
				font-size: 20px;
				line-height: 1.3;
				margin: 0 0 24px;
			}

			@media #{$md} {
				padding: 72px;
				margin: 0 32px 48px;

				h2 {
					font-size: 56px;
					line-height: 1.14;
				}

				p {
					font-size: 32px;
					line-height: 1.31;
					margin: 0 0 48px;
				}
			}

			@media #{$xl} {
				padding: 76px 81px;
				margin: 0;
				width: 640px;
			}
		}

		.image {
			background-image: url('../assets/img/request_a_demo_mobile.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			height: 360px;
			margin: 0 8px 0;
			position: relative;
			z-index: 2;

			@media #{$md} {
				background-image: url('../assets/img/request_a_demo_tablet.jpg');
				margin: 0 32px 0;
				height: 470px;
			}

			@media #{$xl} {
				background-image: url('../assets/img/request_a_demo_desktop.jpg');
				width: 767px;
				height: 622px;
				float: right;
				margin: -304px 0 0 0;
			}
		}
	}
</style>
