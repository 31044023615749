<template>
	<footer>
		<div class="footer-container">
			<div class="logo">
				<a href="#" v-scroll-to="'html'"><img src="../assets/img/logo.svg" /></a>
			</div>
			<div class="text">
				<h2>Contact Us</h2>
				<p>Got a question? Write us at <a href="mailto:hello@goodtalk.org"><strong>hello@goodtalk.org</strong></a> to learn more about using GoodTalk.</p>
				<small>&copy;GoodTalk Ltd. All rights reserved.</small>
			</div>
		</div>
	</footer>
</template>

<script>
	export default {
		name: 'site-footer'
	}
</script>

<style lang="scss">
	@import "../assets/sass/global.scss";

	footer {
		background-color: rgb(77, 77, 77);
		padding: 48px 24px;
		color: white;
		text-align: center;

		.logo {
			a {
				display: inline-block;
				vertical-align: top;
			}
		}

		h2 {
			font-size: 24px;
			line-height: 1;
			font-weight: 600;
			margin: 32px 0;
			color: white;
		}

		p {
			font-size: 18px;
			line-height: 1.44;
			margin: 0;
			color: white;
		}

		a {
			color: white;
			text-decoration: none;
		}

		small {
			display: block;
			margin-top: 48px;
			color: white;
		}

		@media #{$md} {
			height: calc(340px - 96px);
			padding: 48px 80px;

			.footer-container {
				display: grid;
				grid-template-areas: "left right";
				grid-column-gap: 80px;

				.logo { grid-area: left; }

				.text {
					grid-area: right;

					h2, p, a, small { text-align: left; }

					h2 { margin: 0 0 24px; }
					small { margin-top: 24px; }
				}
			}
		}

		@media #{$xl} {
			height: calc(340px - 192px);
			padding: 96px 0;

			.footer-container {
				width: 960px;
				margin: 0 auto;
				grid-column-gap: 174px;

				.text {
					margin-right: 128px;
				}
			}
		}
	}
</style>
