<template>
	<header :class="[{ scrolling : (scrolling || open) }, { open : open }]">
		<nav>
			<div class="nav-container">
				<div>
					<a href="#" v-scroll-to="'html'"><img src="../assets/img/logo.svg" /></a>
				</div>
				<div class="menu">
					<ul>
						<li><a href="#" v-scroll-to="'#partners'">clients</a></li>
						<li><a href="#" v-scroll-to="'.feature.first .content'">features</a></li>
						<li><a href="#" v-scroll-to="'#whos-it-for'">who's it for</a></li>
					</ul>
				</div>
				<div>
					<button @click="toggleMenu" @blur="closeMenu">
						<span></span>
						<span></span>
					</button>
					<a href="#" v-scroll-to="'#learn-more'" class="button">request a demo</a>
				</div>
			</div>
		</nav>
		<template v-if="open">
			<div class="menu standalone">
				<ul>
					<li><a href="#" v-scroll-to="'#partners'">clients</a></li>
					<li><a href="#" v-scroll-to="'.feature.first .content'">features</a></li>
					<li><a href="#" v-scroll-to="'#whos-it-for'">who's it for</a></li>
				</ul>
				<a href="#" v-scroll-to="'#learn-more'" class="button">request a demo</a>
			</div>
		</template>
		<section>
			<h1>Comments worth watching</h1>
			<p>GoodTalk is a video platform that makes it easy to collect & showcase video comments and reviews.</p>
		</section>
	</header>
</template>

<script>
	export default {
		name: 'site-header',
		data() {
			return {
				navHeight: null,
				open: false,
				scrolling: false
			}
		},
		mounted: function() {
			window.addEventListener('scroll', this.scrollHandler);
			this.scrollHandler();
		},
		methods: {
			toggleMenu: function() {
				this.open = !this.open;
			},
			closeMenu: function() {
				var self = this;

				window.setTimeout(function() {
					self.open = false;
				}, 500);
			},
			scrollHandler: function() {
				var scrollTop = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
				this.navHeight = this.$el.querySelector('nav').getBoundingClientRect().height;

				if (scrollTop > this.navHeight) {
					this.scrolling = true;
				} else {
					this.scrolling = false;
				}
			},
			scrollToY: function(scrollTargetY, speed, easing) {
				// scrollTargetY: the target scrollY property of the window
				// speed: time in pixels per second
				// easing: easing equation to use

				var scrollY = window.scrollY || document.documentElement.scrollTop,
					scrollTargetY = scrollTargetY || 0,
					speed = speed || 2000,
					easing = easing || 'easeOutSine',
					currentTime = 0;

				// min time .1, max time .8 seconds
				var time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

				// easing equations from https://github.com/danro/easing-js/blob/master/easing.js
				var easingEquations = {
						easeOutSine: function (pos) {
							return Math.sin(pos * (Math.PI / 2));
						},
						easeInOutSine: function (pos) {
							return (-0.5 * (Math.cos(Math.PI * pos) - 1));
						},
						easeInOutQuint: function (pos) {
							if ((pos /= 0.5) < 1) {
								return 0.5 * Math.pow(pos, 5);
							}
							return 0.5 * (Math.pow((pos - 2), 5) + 2);
						}
					};

				// add animation loop
				function tick() {
					currentTime += 1 / 60;

					var p = currentTime / time;
					var t = easingEquations[easing](p);

					if (p < 1) {
						requestAnimFrame(tick);

						window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
					} else {
						window.scrollTo(0, scrollTargetY);
					}
				}

				// call it once to get started
				tick();
			}
		}
	}
</script>

<style lang="scss">
	@import "../assets/sass/global.scss";

	header {
		padding-top: 64px;
		height: 480px;
		box-sizing: border-box;
		background-image: url('../assets/img/header_mobile.jpg');
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
		display: grid;
		grid-template-columns: 100% auto;
		grid-template-rows: 64px auto;
		color: white;
		grid-template-areas:"nav"
							"text";

		.menu {
			box-sizing: border-box;
			display: none;

			&.standalone {
				display: block;
				position: fixed;
				top: 64px;
				width: 100%;
				text-align: center;
				padding: 48px 32px;
				background-color: white;
				box-shadow: 0 8px 8px 0 rgba(77, 77, 77, 0.1);
				z-index: 5;

				ul {
					margin: 0;
					padding: 0;

					li {
						text-align: left;
						margin-bottom: 24px;

						&:last-child { margin-bottom: 0; }

						a {
							font-size: 16px;
							font-weight: 600;
							text-transform: uppercase;
							text-decoration: none;
							color: $orange;
						}
					}
				}

				a.button {
					margin-top: 60px;
					display: inline-block;
				}
			}
		}

		a.button { display: none; }

		@media #{$md} {
			background-image: url('../assets/img/header_tablet.jpg');
			height: 700px;
		}

		@media #{$xl} {
			grid-template-rows: 96px auto;
			background-image: url('../assets/img/header_desktop.jpg');
			height: 850px;
			padding-top: 96px;

			.menu {
				display: block;

				&.standalone { display: none; }

				ul {
					margin: 0;
					padding: 0;

					li {
						display: inline-block;
						margin-right: 32px;
						height: 48px;
						line-height: 48px;

						a {
							font-size: 16px;
							font-weight: 600;
							text-decoration: none;
							text-transform: uppercase;
							color: white;
						}
					}
				}
			}
		}

		nav {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;

			.nav-container {
				box-sizing: border-box;
				padding: 8px 16px;
				display: grid;
				height: 64px;
				grid-template-columns: 40% auto;
				grid-area: nav;

				@media #{$xl} {
					height: 96px;
					padding: 22px 88px;
					grid-template-columns: 237px auto auto;
					width: calc(1280px - 176px);
					margin: 0 auto;
				}

				div:first-child {
					a {
						display: inline-block;
						vertical-align: top;
						width: 84px;
						padding-top: 8px;

						@media #{$xl} { width: 134px; }

						img {
							display: inline-block;
							width: 100%;
							height: auto;
							vertical-align: top;
						}

						@media #{$xl} { padding: 0; }
					}
				}

				div:last-child {
					text-align: right;

					button {
						box-shadow: none;
						border: 0;
						background-color: transparent;
						width: 48px;
						height: 48px;
						padding: 0;
						margin: 0;
						position: relative;
						transform-origin: center;
						transition: transform 0.3s ease-in-out;
						outline: none;

						span {
							display: block;
							background-color: white;
							width: 32px;
							height: 4px;
							border-radius: 2px;
							margin: 0 auto;
							transition: transform 0.3s ease-in-out;

							&:first-child { margin-bottom: 8px; }
						}
					}

					@media #{$xl} {
						button { display: none; }

						a.button {
							color: white;
							display: inline-block;
							background-color: rgba(255,255,255,0);
							border-color: white;

							&:hover {
								background-color: rgba(255,255,255,0.5);
							}
						}
					}
				}
			}
		}

		&.scrolling {
			nav {
				background-color: white;
				z-index: 6;
				box-shadow: 0 8px 8px 0 rgba(77, 77, 77, 0.1);

				.nav-container {
					div:last-child {
						button {
							span { background-color: $orange; }
						}

						a.button {
							color: rgb(77, 77, 77);
							background-color: rgba(255,255,255,0);
							border-color: $orange;

							&:hover {
								background-color: $light-orange;
							}
						}
					}
				}
			}

			@media #{$xl} {
				nav { margin: 0 auto; }

				.menu {
					ul {
						li {
							a {
								color: $orange;
							}
						}
					}
				}
			}
		}

		&.open {
			nav {
				.nav-container {
					div:last-child {
						button {
							transform: rotate(45deg);

							span {
								transform-origin: center;

								&:first-child {
									transform: rotate(90deg) translateX(6px);
								}

								&:last-child {
									transform: translateY(-6px);
								}
							}
						}
					}
				}
			}
		}

		section {
			text-align: center;
			grid-area: text;
			align-self: center;
			padding: 0 24px;

			h1 {
				font-size: 32px;
				font-weight: 600;
				line-height: 1.25;
				margin: 0;
				color: white;
			}

			p {
				font-size: 15px;
				line-height: 1.2;
				margin: 16px 0 0;
				color: white;
			}

			@media #{$md} {
				padding: 0 148px;

				h1 {
					font-size: 56px;
					line-height: 1.14;
				}

				p {
					font-size: 32px;
					line-height: 1.31;
				}
			}

			@media #{$xl} {
				width: 840px;
				padding: 0;
				margin: 0 auto;

				h1 {
					font-size: 72px;
					line-height: 0.86;
				}
			}
		}
	}
</style>
