<template>
	<div id="features">
		<div class="feature first">
			<div class="content">
				<div class="text">
					<h4>Features</h4>
					<h3>Easy for your audience</h3>
					<p>Video recording is fast and user friendly – three clicks and done. Plus all video reviews and comments appear right on your site so you can keep your customers’ eyes on the prize.</p>
				</div>
				<figure></figure>
			</div>
		</div>
		<div class="feature second">
			<div class="content">
				<div class="text">
					<h4>Features</h4>
					<h3>Easy for you</h3>
					<p>We gather all videos submitted in a custom backend – no messing with YouTube, Vimeo, Dropbox or other links. Once approved, they appear instantly in your showcases.</p>
				</div>
				<figure></figure>
			</div>
		</div>
		<div class="feature third">
			<div class="content">
				<div class="text">
					<h4>Features</h4>
					<h3>White labelled</h3>
					<p>Seamless integration with your site. Your audience never feels they’ve left, or experiences a foreign environment.</p>
				</div>
				<figure></figure>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'features'
	}
</script>

<style lang="scss">
	@import "../assets/sass/global";

	#features {
		.feature {
			position: relative;
			padding: 48px 16px 85px;
			background-repeat: no-repeat;
			background-position: top left;
			background-size: cover;

			figure {
				position: relative;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				height: 220px;
				margin: 0;
			}

			@media #{$md} {
				padding: 96px 124px 105px;
				figure { height: 325px; }
			}

			@media #{$xl} {
				padding: 74px 0;

				.content {
					width: calc(1200px - 160px);
					padding: 0 80px;
					margin: 0 auto;
					display: grid;
					grid-template-areas: "left right";
					grid-template-columns: 50% 50%;

					.text { grid-area: right; }

					figure {
						grid-area: left;
						background-position: left center;
					}
				}
			}

			&.first {
				margin-top: -100px;
				padding-top: 190px;
				padding-bottom: 87px;
				background-image: url('../assets/img/feature_background_mobile.png');

				figure { background-image: url('../assets/img/first_feature_figure.png'); }
				@media #{$md} { background-image: url('../assets/img/feature_background_tablet.png'); }

				@media #{$xl} {
					background-image: url('../assets/img/feature_background_desktop.png');
					margin-top: -460px;
					padding-top: 600px;

					.text {
						grid-area: left;
					}

					figure {
						grid-area: right;
						background-position: right center;
					}
				}
			}

			&.second {
				.text {
					@media #{$xl} { margin-top: 38px; }
				}

				figure {
					height: 250px;
					background-image: url('../assets/img/second_feature_figure.png');

					@media #{$md} { height: 425px; }
				}
			}

			&.third {
				background-color: $light-orange;

				.text {
					@media #{$xl} {
						margin-top: 20px;
						grid-area: left;
					}
				}

				figure {
					height: 242px;
					background-image: url('../assets/img/third_feature_figure.png');

					@media #{$md} { height: 400px; }

					@media #{$xl} {
						grid-area: right;
						background-position: right center;
					}
				}
			}

			h3 {
				font-size: 24px;
				font-weight: 600;
				line-height: 1;
				text-align: center;
				margin: 0 0 24px;

				@media #{$md} { font-size: 32px; }

				@media #{$xl} {
					font-size: 42px;
					text-align: left;
				}
			}

			h4 {
				font-size: 16px;
				font-weight: 900;
				text-transform: uppercase;
				text-align: center;
				color: $orange;
				margin: 0 0 8px;

				@media #{$xl} { text-align: left; }
			}

			p {
				font-size: 16px;
				line-height: 1.5;
				text-align: center;
				margin: 0 0 48px;

				@media #{$md} {
					font-size: 24px;
					line-height: 1.33;
					margin-bottom: 80px;
				}

				@media #{$xl} { text-align: left; }
			}
		}
	}
</style>
