<template>
	<div id="learn-more" :class="{ submitted : submitted }">
		<h2>{{ label }}</h2>
		<template v-if="!submitted">
			<input type="email" id="email" name="email" :class="{ error : emailError }" v-model="email" placeholder="Enter your email address" :disabled="disableElements" />
			<button @click="submit" :disabled="disableElements">Submit</button>
		</template>
	</div>
</template>

<script>
	import axios from 'axios'

	axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

	export default {
		name: 'learn-more',
		data() {
			return {
				email: null,
				emailError: false,
				disableElements: false,
				submitted: false
			}
		},
		computed: {
			label: function() {
				return this.submitted ? "Thanks for your interest! We'll contact you in the next 24 hours." : "Request a GoodTalk demo" ;
			}
		},
		methods: {
			validEmail() {
				const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

				return re.test(this.email);
			},
			submit: function() {
				var self = this;
				this.emailError = false;
				this.submitted = false;
				this.disableElements = true;

				if (this.validEmail()) {
					axios.get('/api/submit', {
						params: {
							email: this.email
						}
					})
					.then(function (res) {
						self.submitted = true;
					})
					.catch(function (err) {
						self.disableElements = false;
						console.log(err);
					});
				} else {
					if (!this.validEmail()) this.emailError = true;
					this.disableElements = false;
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "../assets/sass/global";

	#learn-more {
		padding: 48px 8px;

		h2 {
			font-size: 36px;
			font-weight: 600;
			line-height: 1.22;
			text-align: center;
			margin: 0 0 32px;
		}

		input[type="email"] {
			font-size: 16px;
			box-sizing: border-box;
			border: 2px solid rgb(221, 221, 221);
			box-shadow: none;
			border-radius: 4px;
			width: 100%;
			padding: 14px 16px;
			margin-bottom: 16px;

			&.error { border-color: red; }

			@media #{$md} {
				width: calc(70% - 32px);
				margin-right: 32px;
			}
		}

		button {
			width: 100%;

			@media #{$md} { width: 30%; }
		}

		&.submitted {
			h2 {
				text-align: center;
				font-size: 24px;
				margin: 0;
			}
		}

		@media #{$md} {
			padding: 144px 132px 96px;
			font-size: 0;

			input[type="email"], button {
				display: inline-block;
				vertical-align: top;
			}

			&.submitted { padding-bottom: 144px; }
		}

		@media #{$xl} {
			padding: 148px 0 96px;
			width: 1200px;
			margin: 0 auto;
			display: grid;
			grid-template-columns: auto 316px 156px;
			grid-column-gap: 32px;

			h2 { text-align: left; }

			input[type="email"], button { width: 100%; }

			input[type="email"] {
				padding-top: 5px;
				padding-bottom: 5px;
				height: 48px;
			}

			&.submitted {
				padding-bottom: 148px;
				grid-template-columns: 100%;
				grid-column-gap: 0;
			}
		}
	}
</style>
