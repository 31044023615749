<template>
	<div id="partners">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
</template>

<script>
	export default {
		name: 'partners'
	}
</script>

<style lang="scss">
	@import "../assets/sass/global";

	#partners {
		padding: 48px 8px;
		box-sizing: border-box;
		background-color: white;
		display: grid;
		grid-template-columns: 1fr 1fr;

		div {
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			height: 84px;
		}

		div:nth-child(1) {
			background-image: url('../assets/img/h+k.png');
			background-size: 90%;
		}

		div:nth-child(2) {
			background-image: url('../assets/img/cannabis_amnesty.png');
			background-size: 65%;
		}

		div:nth-child(3) {
			background-image: url('../assets/img/canada.png');
			background-size: 65%;
		}

		div:nth-child(4) { background-image: url('../assets/img/cbc.png'); }

		@media #{$md} {
			padding-left: 134px;
			padding-right: 134px;
			grid-row-gap: 29px;

			div:nth-child(1) { background-size: 90%; }
			div:nth-child(2) { background-size: 65%; }
			div:nth-child(3) { background-size: 65%; }
		}

		@media #{$lg} {
			div:nth-child(1) { background-size: 70%; }
			div:nth-child(2) { background-size: 45%; }
			div:nth-child(3) { background-size: 45%; }
		}

		@media #{$xl} {
			width: 1200px;
			margin: 0 auto;
			padding: 76px 72px;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-column-gap: 32px;

			div:nth-child(1) { background-size: 80%; }
			div:nth-child(2) { background-size: 80%; }
			div:nth-child(3) { background-size: 60%; }
		}
	}
</style>
