<template>
	<div id="whos-it-for">
		<h2>Who’s it for?</h2>
		<p>Whether you’re a consumer products company, a media publisher, a campaigning organization or government, our video commenting platform can help improve your audience engagement.</p>
		<div class="boxes">
			<div class="box products">
				<figure></figure>
				<h2>Products</h2>
				<p>Video reviews are key to turning browsers into buyers. GoodTalk puts them right on your site – no more losing customers searching YouTube for reviews and exposed to the competition.</p>
			</div>
			<div class="box media">
				<figure></figure>
				<h2>Media</h2>
				<p>GoodTalk saves your audience from the trolling and abuse they see in written comment sections and increases engagement through more civil and compelling video comments.</p>
			</div>
			<div class="box public-engagement">
				<figure></figure>
				<h2>Public Engagement</h2>
				<p>GoodTalk strengthens the impact of advocacy and public relations campaigns with the added force of supporters’ views.</p>
			</div>
			<div class="box government">
				<figure></figure>
				<h2>Government</h2>
				<p>GoodTalk helps governments demonstrate they care about the input of citizens and boosts the impact of outreach by amplifying those voices.</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'whos-it-for'
	}
</script>

<style lang="scss">
	@import "../assets/sass/global";

	#whos-it-for {
		padding: 0 8px;

		@media #{$md} { padding: 0 32px; }
		@media #{$xl} {
			width: 1200px;
			margin: 0 auto;
			padding: 0;
		}

		h2 {
			font-size: 24px;
			font-weight: 600;
			margin: 62px 0 16px;
			text-align: center;

			@media #{$md} {
				font-size: 36px;
				margin: 48px 92px 16px;
				line-height: 1.22;
			}

			@media #{$xl} {
				font-size: 42px;
				margin: 94px 0 16px;
				line-height: 1.05;
			}
		}

		p {
			text-align: center;
			color: rgb(118, 118, 118);
			margin: 0;

			@media #{$md} {
				font-size: 24px;
				line-height: 1.33;
				margin: 0 92px;
			}

			@media #{$xl} { margin: 0 215px; }
		}

		.boxes {
			@media #{$xl} {
				display: grid;
				grid-template-areas: "left right";
				grid-column-gap: 48px;
				grid-row-gap:48px;
			}

			.box {
				box-sizing: border-box;
				padding: 48px 8px;
				margin: 48px 0 0;
				border-radius: 8px;
				box-shadow: 0 0 24px 0 rgb(241, 241, 241);

				@media #{$md} {
					padding: 48px;
					display: grid;
					grid-column-gap: 48px;
					grid-template-areas: "left top"
										"left bottom";
					grid-template-columns: 78px auto;
				}

				figure {
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					width: 78px;
					height: 78px;
					margin: 0 auto 24px;

					@media #{$md} {
						grid-area: left;
					}
				}

				&.products {
					figure { background-image: url('../assets/img/products.svg'); }
				}

				&.media {
					figure { background-image: url('../assets/img/media.svg'); }
				}

				&.public-engagement {
					figure { background-image: url('../assets/img/public_engagement.svg'); }
				}

				&.government {
					figure { background-image: url('../assets/img/government.svg'); }
				}

				h2 {
					font-size: 24px;
					font-weight: 600;
					line-height: 1;
					margin: 0 0 24px;

					@media #{$md} {
						font-size: 32px;
						line-height: 1.38;
						grid-area: top;
						text-align: left;
						margin: 0 0 18px;
					}
				}

				p {
					margin: 0;

					@media #{$md} {
						font-size: 18px;
						line-height: 1.67;
						grid-area: bottom;
						text-align: left;
					}
				}
			}
		}
	}
</style>
