<template>
	<div id="app">
		<site-header></site-header>
		<partners></partners>
		<request-a-demo></request-a-demo>
		<features></features>
		<whos-it-for></whos-it-for>
		<learn-more></learn-more>
		<site-footer></site-footer>
	</div>
</template>

<script>
	import SiteHeader from './components/SiteHeader'
	import Partners from './components/Partners'
	import RequestADemo from './components/RequestADemo'
	import Features from  './components/Features'
	import WhosItFor from './components/WhosItFor'
	import LearnMore from './components/LearnMore'
	import SiteFooter from './components/SiteFooter'

	export default {
		name: 'app',
		components: {
			SiteHeader,
			Partners,
			RequestADemo,
			Features,
			WhosItFor,
			LearnMore,
			SiteFooter
		}
	}
</script>

<style lang="scss">
	@import "./assets/sass/global";
</style>
